body {
    background: #000;
    color: #ffffff;
}
.hero-background {
    background-color: black;
    background-image: url("../assets/images/hero.jpg");
    filter: blur(20px);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110%;
    height: 110%;
    left: -20px;
    top: -20px;
    position: absolute;
    z-index: -1;
}
.appcontainer{
    position: relative;
}

h1{
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }